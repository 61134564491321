@font-face {
    font-family: 'Bohemian Soul';
    src: url(../../assets/fonts/Bohemian-Soul.otf) format('opentype');
}

@font-face {
    font-family: 'Gontserrat';
    src: url(../../assets/fonts/Gontserrat-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Gontserrat';
    src: url(../../assets/fonts/Gontserrat-Italic.ttf) format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Gontserrat';
    src: url(../../assets/fonts/Gontserrat-Bold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Gontserrat';
    src: url(../../assets/fonts/Gontserrat-BoldItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 600;
}

/* Font-family with fallback */
body {
    font-family: 'Bohemian Soul', 'Gontserrat', Arial, sans-serif;
}

h1,
h2,
h3,
p {
    font-family: 'Gontserrat', 'Arial', sans-serif;
}

/* Font-family for italic and bold styles */
em {
    font-family: 'Gontserrat', Arial, sans-serif;
    font-style: italic;
}

strong {
    font-family: 'Gontserrat', Arial, sans-serif;
    font-weight: 600;
}