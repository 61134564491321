@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200%;

    &-fore {
        animation: wave-left linear infinite 6s;
        z-index: 2;

        & svg {
            position: relative;
            display: block;
            width: 200%;
            height: 1.5rem;

            @include md {
                height: 7.5rem;
            }
        }

        &-fill {
            fill: $portfolio-color--black;
        }

    }

    &-mid {
        animation: wave-right linear infinite 10s;
        z-index: 1;

        & svg {
            position: relative;
            display: block;
            width: 200%;
            height: 3.75rem;

            @include md {
                height: 13.5rem;
            }
        }

        &-fill {
            fill: $portfolio-color--pink;
        }
    }

    &-back {
        animation: wave-left linear infinite 12s;
        z-index: 0;

        & svg {
            position: relative;
            display: block;
            width: 200%;
            height: 6.25rem;

            @include md {
                height: 15.625rem;
            }
        }

        &-fill {
            fill: $portfolio-color--blue;
        }
    }

}

.hero {
    @include flex(row, center, center);
    background-color: $portfolio-color--pink;
    background: linear-gradient(0deg, rgba(194, 108, 100, 1) 0%, $portfolio-color--pink 50%);
    background-size: cover;
    height: 16.875rem;
    width: 100%;
    overflow: hidden;
    position: relative;

    @include md {
        height: 43.75rem;
    }

    @include lg {
        height: 50rem;
    }

    &__cont {
        @include flex(row, center, center);

        @include md {
            width: 100%;
        }
    }


    &__sec {
        @include flex(row, center, center);

        @include md {
            width: 40%;
        }
    }

    &__info {
        @include flex(column, center, center);

        &:first-of-type {
            width: 100%;
            z-index: 6;
            position: absolute;

            @include md {
                width: 47%;
            }
        }
    }

    &__text {
        text-align: center;
        margin: 1.5rem 0rem;

        @include md {
            margin: 1rem 0rem;
        }
    }

    &__title {
        @include main-font(1.5rem, 400, none);
        margin: 1rem 0rem;

        @include md {
            @include main-font(2.5rem, 400, none);
            margin: 0rem 0rem;
        }

        @include lg {
            @include main-font(3rem, 400, none);
            margin: 1rem 0rem;
        }
    }

    &__subtitle {
        @include main-font(.875rem, 400, 1rem);

        @include md {
            @include main-font(1.125rem, 400, 2rem);

            &::selection {
                background-color: $portfolio-color--black;
                color: $portfolio-color--white;
            }

        }

        @include lg {
            @include main-font(1.5rem, 400, 2rem);
        }
    }

    &__desktop {
        &--btn {
            @include md {
                margin: 1rem;
            }
        }
    }

    &__mobile {

        &--btn {
            display: flex;
            flex-direction: column;
            max-width: 15.625rem;
            min-height: 2.5rem;
            width: 80%;

            &>* {
                background-color: rgba($portfolio-color--blue, 1);
                border: $portfolio-color--blue solid .25rem;
                box-shadow: 0rem .625rem .9375rem -0.1875rem rgba(0, 0, 0, 0.2);

                &:hover {
                    background-color: rgba($portfolio-color--black, 1);
                    border: $portfolio-color--black solid .25rem;
                }
            }

            @include md {
                display: none;
            }
        }
    }

    &__model {
        display: none;

        @include md {
            @include flex(row, center, center);
            padding: .5rem 0rem;
            height: 21.875rem;
            width: 80%;
        }
    }
}