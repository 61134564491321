@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.hero-btn {
    display: none;

    @include md {
        @include flex(row, space-between, center);
        width: 20rem;
        padding: 1rem;

        &--divider {
            background-color: $portfolio-color--white;
            width: 0rem;
            height: 0rem;
            border-radius: 3.75rem;
            transition: ease-in-out .2s;
        }

        &:hover .hero-btn--divider {
            width: .25rem;
            height: 1.5rem;
            margin: -1rem 0rem;
        }

        &--opt {
            @include sub-font(1.5rem, 600, 0.8);
            color: $portfolio-color--black;
            text-transform: uppercase;
            line-height: 0;
            padding: .125rem .25rem;
            position: relative;
            cursor: pointer;

            &::selection {
                background-color: $portfolio-color--black;
                color: $portfolio-color--white;
            }



            &::after,
            &::before {
                content: '';
                position: absolute;
                width: 0%;
                margin: 1.5rem 0rem;
                transition: ease .45s;
            }

            &:first-of-type:hover {

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $portfolio-color--white;
                    height: .25rem;
                    width: 100%;
                    border-radius: .125rem;
                }

                &::after {
                    right: 0rem;
                    top: -0.125rem;

                }

                &::before {
                    right: 0;
                    bottom: 0rem;
                }
            }

            &:last-of-type:hover {

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $portfolio-color--white;
                    height: .25rem;
                    width: 100%;
                    border-radius: .125rem;
                }

                &::after {
                    left: 0rem;
                    top: -0.125rem;
                }

                &::before {
                    left: 0;
                    bottom: 0rem;
                }
            }
        }
    }


}