@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.designcard {
    @include flex(column, center, center);
    overflow: hidden;
    position: relative;
    break-inside: avoid;
    margin-bottom: 1rem;
    width: 100%;
    transition: ease .5s;

    &_img {
        width: 100%;
        transition: ease .5s;

        @include md {
            &:hover {
                scale: 1.02;
                cursor: pointer;
            }

            &:hover~.designcard_desc {
                bottom: -0.625rem;
            }
        }
    }

    &_desc {
        display: none;

        @include md {
            @include sub-font(.875rem, 400, 1.5);
            display: inline-block;
            position: absolute;
            text-align: center;
            pointer-events: none;
            background-color: rgba($portfolio-color--white, .5);
            backdrop-filter: blur(.5rem);
            width: 100%;
            bottom: -12.5rem;
            padding: .75rem .75rem 1.5rem .75rem;
            transition: ease .5s;
            transition-delay: .6s;
        }
    }
}