$breakpoints: (
    "sm": 20rem,
    "md": 48rem,
    "lg": 80rem,
);

@mixin sm {
    @media(min-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin md {
    @media(min-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin lg {
    @media(min-width: map-get($breakpoints, "lg")) {
        @content;
    }
}