@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;


.design-page {
    @include flex(column, center, center);
    background-color: $portfolio-color--white;

    &__hero {
        @include flex(column, center, center);
        background-color: $portfolio-color--navy;
        overflow: hidden;
        width: 100%;
        height: 200px;
        padding: 40px 0px 0px 0px;
        top: 0;

        @include md {
            height: 300px;
        }

        &--text {
            @include flex(column, center, center);
            color: $portfolio-color--white;
            padding: 4px 0px;
            transition: ease-in-out 0.8s;

            &:first-child {
                @include main-font(16px, none, none);
                font-variant-ligatures: no-common-ligatures;
                top: 100px;

                @include md {
                    @include main-font(24px, none, none);
                    top: 110px;
                }
            }

            &:last-child {
                @include sub-font(14px, none, none);
                top: 125px;

                @include md {
                    @include sub-font(16px, none, none);
                    top: 160px;
                }
            }
        }
    }

    &__cont {
        @include flex(column, center, center);
        background-color: $portfolio-color--white;
        width: 100%;
        z-index: 1;

        &--cards {
            box-sizing: border-box;
            width: 100%;
            gap: 16px;
            columns: 1;
            padding: 20px 0px 0px 0px;

            @include md {
                padding: 20px 0px;
                width: 75%;
                columns: 2;
            }

            @include lg {
                width: 80%;
                columns: 3;
            }
        }

        &--group {
            @include flex(row, center, center);
            @include sub-font(14px, none, none);
            text-align: center;
            padding: 20px 0px 40px 0px;
            width: 100%;
            position: relative;
        }

        &--text {
            font-style: italic;
            opacity: 80%;
        }

        &--svg {
            display: none;

            &:last-child {
                right: 10px;
                width: 150px;
                animation: sticker 1.5s steps(1) infinite reverse;
            }

            @include md {
                display: flex;
                position: absolute;
                fill: $portfolio-color--pink;
                width: 100px;
                right: 40px;
                bottom: 20px;
                opacity: 50%;
                animation: sticker 1.5s steps(1) infinite;
                z-index: -1;
            }
        }
    }
}