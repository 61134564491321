@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.aboutme {
    @include flex(column, center, center);
    background-color: $portfolio-color--navy;
    width: 100%;
    padding: 3.75rem 0rem;
    position: relative;
    overflow: hidden;

    & * {
        color: $portfolio-color--white;
    }

    &__sec {
        width: 100%;

        @include md {
            width: 90%;
        }

        @include lg {
            width: 80%;
        }
    }

    &__cont {
        @include flex(row, center, flex-start);
        width: 100%;

        @include md {
            width: 100%;
        }

        @include lg {
            width: 90%;
            max-width: 87.5rem
        }
    }

    &__body {
        @include flex(column, center, center);
        height: 31.25rem;

        @include md {
            @include flex(column, flex-start, center);
            width: 65%;
        }
    }

    &__header {
        @include flex(column, center, center);
        width: 100%;

        @include md {
            @include flex(row, flex-start, flex-start);
            width: 100%;
            margin-bottom: 2rem;
        }

        &--title {
            @include main-font(2rem, 500, 2.5rem);
            display: none;

            @include md {
                display: none
            }
        }

        &--info {
            @include flex(column, center, center);
        }

        &--icon {
            width: 2.8125rem;
            margin: 0rem 0rem .5rem 0rem;

            @include md {
                display: none
            }
        }

        &--text {
            @include flex(column, center, center);
            @include sub-font(1rem, 700, 1.25rem);

            &::after {
                content: "";
                background-color: $portfolio-color--pink;
                border-radius: .25rem;
                margin: .25rem;
                width: 0rem;
                height: .1875rem;
                transition: 1.5s ease-out 0.2s;
            }

            @include md {
                display: none
            }
        }
    }

    &__mobile {

        &--body {
            @include flex(row, center, center);
            margin: 1rem;

            @include md {
                display: none
            }
        }

        &--profile {
            width: 9.375rem;
            border-radius: 100%;
            border: .375rem solid $portfolio-color--white;
            z-index: 100;
        }

    }

    &__text {
        @include flex(column, center, center);
        text-align: center;
        width: 80%;
        z-index: 1;

        @include md {
            width: 80%;
        }
    }

    &__desc {
        @include sub-font(1rem, 500, 1.5625rem);
        margin: .5rem 0rem;

        @include md {
            @include sub-font(1.125rem, 500, 1.875rem);
            text-align: start;
        }
    }

    &__list {
        display: none;

        @include md {
            @include flex(column, flex-start, flex-start);
            padding: .5rem 0rem;
            width: 100%;
        }

        &--item {
            @include sub-font(1.125rem, 500, 1.5);
            text-align: left;
            margin: .5rem 0rem;
            opacity: 0;
            filter: blur(.3125rem);
            translate: -6.25rem 0rem;
            transition: ease 0.4s 0.3s;

            &:nth-of-type(2) {
                transition-delay: 0.35s;
            }

            &:nth-of-type(2) {
                transition-delay: 0.40s;
            }

            &:nth-of-type(3) {
                transition-delay: 0.45s;
            }
        }

        &--link {
            color: $portfolio-color--white;
            text-decoration: underline;
            font-style: italic;
            cursor: pointer;

            &:hover {
                color: $portfolio-color--pink;
            }
        }
    }

    &__desktop {
        display: none;

        @include md {
            display: flex;
        }

        &--profile {
            width: 15.625rem;
            border: .25rem solid $portfolio-color--white;
            border-radius: .25rem;
            transition: ease .3s;
            margin: 1rem;

            @include lg {
                width: 22.5rem;
                margin: 0rem;
            }
        }
    }

    &__img {
        &--svg {
            display: none;

            @include md {
                display: flex;
                position: absolute;
                bottom: 2.5rem;
                right: 2.5rem;
                opacity: 50%;
                z-index: 0;

                &:first-child {
                    width: 10.625rem;
                    fill: none;
                    transform-origin: center 7.5rem;
                    animation: sticker 1.5s steps(1) infinite;
                }

                &:last-child {
                    width: 12.5rem;
                    bottom: 5.625rem;
                    right: 3.75rem;
                    fill: $portfolio-color--pink;
                    transform: scaleX(-1);
                    animation: sticker 1.5s steps(1) infinite reverse;
                }
            }

            @include lg {
                left: 3.125rem;
                bottom: 1.25rem;

                &:first-child {
                    transform: scaleX(-1);
                }

                &:last-child {
                    transform: scaleX(1);
                }
            }
        }
    }
}

.img-- {
    &brush {
        width: .75rem;
        rotate: -40deg;
        translate: 1.625rem -3.125rem;
        transition: ease 1.5s;

        animation: brushAnimation ease 1.4s forwards;
        @include rotateLeft(38deg, brushAnimation);
    }

    &game {
        width: 1.75rem;
        translate: -0.5rem 3.125rem;
        transition: ease;

        animation: gameAnimation ease 1.4s forwards;
        @include rotateLeft(-38deg, gameAnimation);
    }

    &book {
        width: 2.25rem;
        transform: translate(1.875rem, -3rem);
        transition: ease;

        animation: bookAnimation ease 1.4s forwards;
        @include rotateRight(-32deg, bookAnimation);
    }

    &plane {
        width: 1.5rem;
        transform: translate(1.25rem, 3.75rem);
        transition: ease;

        animation: planeAnimation ease 1.4s forwards;
        @include rotateRight(50deg, planeAnimation);
    }
}