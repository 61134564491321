@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;


.skills {
    @include flex(column, center, center);
    background-color: $portfolio-color--white;
    min-height: 38.75rem;
    width: 100%;

    @include md {
        padding: 2.5rem 0rem;
    }

    &__body {
        @include flex(column, center, center);
        width: 100%;

        &::after {
            content: '';
            background-color: $portfolio-color--navy;
            width: 100%;
            height: 5rem;

            @include md {
                all: unset;
            }
        }
    }

    &__main {
        @include flex(column, center, center);
        width: 100%;
    }

    &__sec {
        @include flex(column, center, center);
        margin: 0rem 0rem .5rem 0rem;

        @include md {
            margin: 0rem 0rem 1rem 0rem;
            width: 100%;
        }
    }

    &__header {
        @include flex(column, center, center);
        width: 100%;
        padding: .5rem 0rem;

        @include md {
            @include flex(column, center, flex-start);
            padding: 1rem 0rem;
            width: 90%;
        }

        @include lg {
            width: 80%;
        }

        &--icon {
            width: 2.8125rem;
            margin: 0rem 0rem .5rem 0rem;

            @include md {
                display: none
            }
        }

        &--text {
            @include flex(column, center, center);
            @include sub-font(1rem, 700, 1.25rem);

            @include md {
                display: none
            }
        }
    }

    &__subheader {
        @include sub-font(1rem, 600, 1.25rem);
        background-color: $portfolio-color--navy;
        color: $portfolio-color--white;
        text-align: center;
        width: 100%;
        padding: 1rem 0rem;

        @include md {
            @include sub-font(1.5rem, 600, 1.25rem);
            background-color: transparent;
            color: $portfolio-color--black;
        }
    }

    &__cont {
        @include flex(column, center, center);
        width: 80%;

        @include md {
            width: 90%;
        }

        @include lg {
            width: 100%;
        }
    }

    &__group {
        @include flex(row, center, center);
        flex-wrap: wrap;
        width: 95%;

        @include md {
            @include flex(row, center, center);
            flex-wrap: wrap;
        }

        @include lg {
            width: 100%;
        }
    }
}