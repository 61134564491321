@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;


.notfound {
    @include flex(column, center, center);
    background-color: $portfolio-color--white;
    height: 100vh;
    width: 100%;
    scale: 1.25;

    @include md {
        scale: unset;
        height: 800px;
    }

    &__cont {
        @include flex(column, center, center);
        width: 100%;
        padding: 16px 0px;
        transition: ease-in-out .5s;

        @include lg {
            @include flex(row-reverse, center, center);
            width: 60%;
        }
    }

    &__group {
        @include flex(column, center, center);
        position: relative;
        background-color: $portfolio-color--pink;
        border-radius: 100%;
        padding: 48px;
        margin: 8px;

        @include md {
            scale: 1.5;
            margin: 48px;
        }

        @include lg {
            scale: 2;
        }

        &--text {
            @include sub-font(8px, unset, unset);
            color: $portfolio-color--white;
            font-weight: 500;
            font-style: italic;
            position: absolute;
            top: 55px;
            right: 24px;
            animation: sticker 1s steps(1) infinite;
            opacity: 70%;
        }

        &--img {
            width: 120px;
            transition: ease-in-out .5s;
            opacity: 0;
            animation: snapFrames 2s infinite steps(1);

            &:nth-child(1) {
                opacity: 1;
            }

            &:nth-child(2) {
                animation-delay: 1s;
                position: absolute;
                bottom: 48px;
                left: 54px;
            }
        }
    }

    &__desc {
        @include flex(column, center, center);
        text-align: center;
        margin: 16px 0px;
        width: 100%;
        z-index: 2;

        &--404 {
            @include sub-font(48px, 600, none);
            margin: 16px 0px;

            @include md {
                @include sub-font(64px, 600, none);
                margin: 8px 0px;
            }

            @include lg {
                @include sub-font(96px, 600, none);
                margin: 16px 0px;
            }
        }

        &--text {
            @include sub-font(12px, 600, none);

            @include md {
                @include sub-font(18px, 600, none);
            }

            @include lg {
                @include sub-font(20px, 600, none);
                margin: 8px 0px;
            }
        }

        &--link {
            @include sub-font(12px, 500, none);
            margin: 16px 0px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: $portfolio-color--pink;
            }

            @include md {
                @include sub-font(16px, 600, none);
                margin: 0px;
            }
        }
    }
}