@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.socials-modal {

    &.slide-in {
        display: none;

        @include md {
            display: unset
        }
    }

    @include md {
        @include flex(column, center, center);
        background-color: rgba(255, 255, 255, .5);
        backdrop-filter: blur(.5rem);
        box-shadow: 0rem .25rem .75rem -0.3125rem rgba(0, 0, 0, 0.4);
        border-radius: .5rem 0rem 0rem .5rem;
        position: fixed;
        top: 15%;
        right: 0;
        margin: .5rem -0.0625rem;
        padding: .5rem .5rem 1rem .5rem;
        z-index: 100;
        transition: ease .3s;

        &__cont {
            @include flex(column, center, center);
        }

        &__title {
            @include main-font(1rem, 700, none);
            letter-spacing: .0313rem;
            padding: 0rem 0rem .5rem 0rem;
        }

        &__link {
            @include flex(row, center, center);
            position: relative;

            &::before {
                content: '';
                @include sub-font(1rem, 700, none);
                background-color: $portfolio-color--white;
                color: $portfolio-color--white;
                position: absolute;
                right: 0;
                margin: 0rem 1.875rem .75rem 0rem;
                padding: 0rem .5rem;
                border-radius: .625rem .625rem 0rem .625rem;
                overflow: hidden;
                opacity: 0;
                z-index: 100;
                transition: ease .3s;
            }

            &:hover::before {
                opacity: 1;
                color: $portfolio-color--black;
                margin: 0rem 2.5rem 1rem 0rem;
            }

            &:nth-of-type(1) {
                &::before {
                    content: 'LinkedIn';
                    background-color: rgba(35, 87, 137, 0.9);
                    color: $portfolio-color--white;
                    pointer-events: none;
                }
            }

            &:nth-of-type(2) {
                &::before {
                    content: 'GitHub';
                    background-color: rgba(30, 27, 25, 0.9);
                    color: $portfolio-color--white;
                    pointer-events: none;
                }
            }

            &:nth-of-type(3) {
                &::before {
                    content: 'IDK';
                    background-color: rgba(248, 248, 248, 0.9);
                    color: $portfolio-color--black;
                }
            }
        }

        &--icon {
            width: 2.1875rem;
            margin: .125rem 0rem;
            background-color: $portfolio-color--black;
            border-radius: .25rem;
            border: .125rem solid white;
        }
    }

}