@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.footer {
    @include flex(column, center, center);
    background-color: $portfolio-color--black;
    height: 8.75rem;
    width: 100%;
    z-index: 998;

    @include md {
        @include flex(row, center, center);
        height: 10rem;
    }

    &__cont {
        @include flex(column, space-between, center);
        margin: 0rem .5rem;
        width: 100%;

        @include md {
            @include flex(row, space-between, center);
            width: 100%;
        }

        @include lg {
            width: 80%
        }
    }

    &__sec {
        @include flex(row, space-evenly, center);
        width: 95%;

        &:first-of-type {
            width: 50%;
            max-width: 12.5rem;
        }

        @include md {
            @include flex(column, space-between, center);
            width: 58%;

            &:first-of-type {
                max-width: 31.25rem;
            }
        }

        &--group {
            display: none;

            @include md {
                @include flex(column, space-between, center);
                width: 100%;
                text-align: center;
            }
        }

        &--name {
            @include sub-font(1.125rem, 600, 1.2);
            color: $portfolio-color--white;
            margin: .25rem 0rem;

            @include lg {
                @include sub-font(1.5rem, 600, 1.2);
            }
        }

        &--text {
            @include sub-font(.75rem, 500, 1.2);
            color: $portfolio-color--white;
            margin-bottom: 1rem;

            @include lg {
                @include sub-font(1rem, 500, 1.2);
            }
        }

        &--sitenav {
            display: none;

            @include md {
                @include flex(row, space-between, center);
                width: 100%;
                max-width: 21.875rem;
                margin: .25rem 0rem;
            }
        }

        &--link {
            color: $portfolio-color--white;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &--socials {
            color: $portfolio-color--white;
            @include flex(row, space-between, center);
            margin: 0;
            width: 100%;

            @include md {
                width: 50%;
                max-width: 12.5rem;
            }
        }

        &--icon {
            width: 3.125rem;
            margin: 0rem 0rem .25rem 0rem;

            @include md {
                display: none;
            }

            &-desktop {
                display: none;

                @include md {
                    display: unset;
                    width: 3.125rem;
                    cursor: pointer;
                }
            }

        }
    }

    &__text {
        @include flex(row, center, center);
        @include sub-font(.75rem, 700, 1rem);
        color: $portfolio-color--white;
        text-align: center;
        width: 100%;
        margin: .5rem 0rem;

        &:last-of-type {
            display: none
        }

        @include md {
            @include sub-font(1rem, 700, 1rem);

            &:last-of-type {
                display: unset;
                @include sub-font(.75rem, 500, 1rem);
                color: $skeleton-color--gray
            }

            &--highlight {
                @include sub-font(.75rem, 500, 1rem);
                color: $skeleton-color--gray;
                font-style: italic;

                &:hover {
                    text-decoration: underline;
                    color: $portfolio-color--pink
                }
            }
        }
    }

    &__svg--rakun {
        display: none;

        @include md {
            display: flex;
            position: relative;
            width: 3.125rem;
            fill: $portfolio-color--white;
            margin: .5rem 0rem;
            animation: ease .5s;

            &::after {
                content: '"' var(--greeting-content) '"';
                position: absolute;
                text-align: center;
                font-style: italic;
                font-weight: 600;
                transform-origin: left bottom;
                background-color: $portfolio-color--pink;
                left: 3.75rem;
                top: -0.875rem;
                width: auto;
                padding: .125rem .375rem;
                border-radius: .25rem .25rem .25rem 0rem;
                overflow: hidden;
                white-space: nowrap;
                transition: ease 0.2s;
                opacity: 0;
                pointer-events: none;
                z-index: 999;
            }

            &:hover {
                cursor: pointer;

                &::after {
                    opacity: 1;
                    animation: sticker 1s steps(1) infinite;
                    animation-delay: .1s;
                }
            }
        }
    }
}