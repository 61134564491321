@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;


.projects {
    @include flex(column, center, center);
    background-color: $portfolio-color--navy;
    padding: 2.5rem 0rem 1.25rem 0rem;
    width: 100%;

    &__header {
        @include flex(column, center, center);
        background-color: $portfolio-color--white;
        width: 100%;
        padding: .5rem 0rem;

        & h4 {
            color: $portfolio-color--white;
        }

        @include md {
            @include flex(column, center, flex-start);
            background-color: transparent;
            padding: 1rem 0rem;
            width: 90%;
        }

        @include lg {
            width: 80%;
        }

        &--icon {
            width: 3.125rem;
            margin: 0rem 0rem .25rem 0rem;

            @include md {
                display: none
            }
        }

        &--text {
            @include flex(column, center, center);
            @include sub-font(1rem, 700, 1.25rem);

            @include md {
                display: none
            }
        }
    }

    &__sec {
        @include flex(column, center, center);
    }

    &__group {
        @include flex(row, center, center);
        flex-wrap: wrap;

        &--link {
            @include flex(row, center, center);
            color: $portfolio-color--white;

            margin: 1.25rem;

            @include md {
                &:hover {
                    color: $portfolio-color--pink;
                    text-decoration: underline;
                }
            }
        }
    }

    &__body {
        @include flex(column, center, center);
        padding: 1rem 0rem;
        width: 100%;
    }

    &__text {
        @include sub-font(1rem, 500, 1.8);
        color: $portfolio-color--white;
        text-align: justify;
        margin: 1rem 0rem .5rem 0rem;
        min-width: 73%;
        max-width: 80%;

        @include md {
            @include sub-font(1.125rem, 500, 2);
            width: 88%;
            margin: 0rem 0rem .5rem 0rem;
        }

        @include lg {
            width: 75%;
        }
    }

    &__opts {
        @include flex(column, center, center);
        margin: 1rem 0rem;
        width: 100%;

        @include md {
            @include flex(row, center, center);
            width: unset;
        }

        & * {
            color: $portfolio-color--white;
            border-color: $portfolio-color--white;
            min-width: 5.625rem;
            width: 85%;

            @include md {
                min-width: 6.25rem;
                width: unset;
            }

            @include lg {
                min-width: 7.5rem;
            }

            &:hover {
                animation: none;
            }
        }
    }

    &__btns {
        margin: 1rem;
    }
}