@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.btn {
    @include btn($portfolio-color--black, transparent, $portfolio-color--black);
    @include flex(row, center, center);
    box-shadow: 0rem .375rem 1.4375rem -0.25rem rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    min-width: 4rem;
    min-height: 2.1875rem;
    padding: 1rem .5rem;
    margin: .5rem;
    border-radius: .25rem;
    cursor: pointer;
    transition: ease-out .2s;

    @include md {
        border-radius: .25rem;
        padding: .5rem 2rem;
    }

    @include lg {
        padding: .5rem 1.25rem;
    }

    &-text {
        @include sub-font(1rem, 600, 1rem);
        letter-spacing: .0313rem;
        color: $portfolio-color--white;

        @include md {
            @include sub-font(1.5rem, 600, 1.875rem);
        }
    }

    &:hover {
        @include md {
            @include btn($portfolio-color--white, $portfolio-color--black, $portfolio-color--black);
            box-shadow: 0rem .375rem 1.4375rem -0.25rem rgba(0, 0, 0, 0.18);
            scale: 1.015;
            animation: shake ease .4s infinite;

            &>.btn-text {
                color: $portfolio-color--white;
            }
        }


    }
}

#btn--active {
    @include btn($portfolio-color--white, $portfolio-color--blue, $portfolio-color--blue);
    animation: none;
    scale: none;

    &:active {
        @include btn($portfolio-color--white, $portfolio-color--navy, $portfolio-color--navy);
    }

    & span {
        color: $portfolio-color--white;
    }
}