@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.section-header {
    display: none;

    @include md {
        @include flex(row, flex-start, center);
        width: 100%;
    }

    &--title {
        @include main-font(2rem, 500, 2.5rem);
        color: $portfolio-color--pink;
        display: none;

        @include md {
            @include flex(column, center, flex-start);
            text-align: start;
        }
    }

    &--text {
        @include flex(column, center, center);
        @include sub-font(1rem, 700, 1.25rem);

        &::after {
            content: "";
            background-color: $portfolio-color--pink;
            border-radius: .25rem;
            margin: .25rem;
            width: 0rem;
            height: .1875rem;
            transition: 1.5s ease-out 0.2s;
        }

        &.animation--underline::after {
            width: 5rem;
            height: .1875rem;
        }

        @include md {
            @include flex(row, center, center);
            @include sub-font(2rem, 700, 2.5rem);

            &::after {
                display: none;
            }

            &::before {
                content: "";
                background-color: $portfolio-color--pink;
                border-radius: .125rem;
                margin: 0rem .5rem;
                width: .1875rem;
                height: 0rem;
                transition: .9s ease-out 0.2s;
            }

            &.animation--underline::before {
                width: .1875rem;
                height: 2rem;
            }
        }
    }
}