@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.progress-bar {
    @include flex(column, center, center);
    margin: .5rem;
    padding: .25rem;

    &__cont {
        @include flex(column, center, center);
        position: relative;
        height: 4.6875rem;
        width: 4.6875rem;
        border-radius: 50%;

        @include md {
            height: 6.25rem;
            width: 6.25rem;
        }

        @include lg {
            height: 7.1875rem;
            width: 7.1875rem;
        }

        &::after {
            content: "";
            position: absolute;
            background-color: $portfolio-color--white;
            width: 3.4375rem;
            height: 3.4375rem;
            border-radius: 50%;

            @include md {
                height: 4.6875rem;
                width: 4.6875rem;
            }

            @include lg {
                height: 5.625rem;
                width: 5.625rem;
            }
        }

    }

    &__value,
    &__text {
        @include sub-font(.75rem, 600, 1.5);
        margin: .25rem 0rem;

        @include md {
            @include sub-font(.875rem, 600, 1.5);
            margin: .5rem 0rem;
        }
    }

    &--img {
        width: 2.125rem;
        z-index: 2;

        @include md {
            width: 2.8125rem;
        }

        @include lg {
            width: 3.4375rem;
        }
    }
}