@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.navbar {
    @include flex(row, center, center);
    background-color: rgb(227, 228, 229, 0.8);
    box-shadow: 0rem .125rem .25rem rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(.2813rem);
    width: 100%;
    height: 2.8125rem;
    padding: 2rem 0rem;
    position: fixed;
    top: 0;
    z-index: 999;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &--visible {
        transform: translateY(0);
        opacity: 1;
    }

    &--hidden {
        transform: translateY(-100%);
        opacity: 0;
    }

    @include md {
        height: 4.0625rem;
    }

    @include lg {
        height: 4.375rem;
    }

    &--theme {
        display: none;

        @include md {
            display: flex;
            display: none;
            // NEED TO WORK ON THIS    
        }
    }

    &__logo {
        @include flex(row, center, center);
        width: 100%;
        scale: 1.4;

        &--desktop {
            cursor: pointer;
            width: 2.625rem;
            margin: 0rem .5rem;
            padding: .5rem;

            @include md {
                width: 3.5rem;
                scale: 0.7;
            }
        }
    }

    &__cont {
        width: 90%;

        @include md {
            @include flex(row, space-between, center);
        }
    }

    &__sec {
        @include flex(row, space-evenly, center);

        @include md {
            &:first-of-type {
                width: 15%;
            }
        }
    }

    &__opts {
        @include flex(row, space-between, center);
        margin: 0rem .5rem;
    }

    &__links {
        display: none;
        position: relative;
        cursor: pointer;

        @include md {
            @include flex(row, space-between, center);
            @include main-font(1.125rem, 500, 1.6875rem);
            margin: 0rem .5rem;
            position: relative;

            &::after,
            &::before {
                content: '';
                position: absolute;
                width: 0%;
                margin: 1.75rem 0rem;
                transition: ease .45s;
            }

            &::before {
                content: '';
                transition: ease .55s .05s;
            }

            &:first-of-type:hover {

                &::after,
                &::before {
                    content: '';
                    background-color: $portfolio-color--black;
                    height: .1875rem;
                    width: 100%;
                    border-radius: .125rem;
                    position: absolute;

                }

                &::after {
                    top: 0rem;
                }

                &::before {
                    bottom: 0rem;
                }
            }
        }

        &--mobile {
            @include flex(column, space-between, center);
            font-weight: 600;
            padding: .5rem 1rem;
            width: 18.75rem;

            &::after {
                content: '';
                position: absolute;
                margin: 1rem 0rem;
                transition: ease .2s;

            }

            &:hover {
                cursor: pointer;

                &::after {
                    content: '';
                    background-color: $portfolio-color--black;
                    height: .1875rem;
                    min-width: 4.6875rem;
                    border-radius: .125rem;
                    margin: 1.25rem 0rem;
                    position: absolute;
                }
            }

            @include md {
                display: none;
            }
        }
    }
}