@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/breakpoints' as *;

.stacktag {
    @include sub-font(.75rem, 600, normal);
    background-color: $portfolio-color--pink;
    margin: .25rem;
    padding: .375rem .5rem;
    border-radius: 3.125rem;
    transition: ease-in-out .3s;

    &--title {
        @include sub-font(.75rem, 600, none);
        text-align: center;
        line-height: normal;
    }
}