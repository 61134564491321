@use './mixins' as *;
@use './breakpoints' as *;
@use './typography' as *;

$portfolio-color--pink: #E9A18B;
$portfolio-color--grey: #E3E4E5;
$portfolio-color--white: #F8F8F8;
$portfolio-color--blue: #235789;
$portfolio-color--navy: #1E1B19;
$portfolio-color--black: #1E1B19;

$skeleton-color--light-gray: #ececec;
$skeleton-color--gray: #939596;
$skeleton-color--dark-gray: #4d4d4e;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    border: none;
    font-family: 'Gontserrat';
    color: $portfolio-color--black;
    text-decoration: none;
    transition: ease-in-out .2s;
}

::selection {
    background-color: $portfolio-color--pink;
    color: $portfolio-color--black;
}

h1 {
    @include sub-font(1.25rem, 400, 1rem);

    @include md {
        @include sub-font(3rem, 400, 3.75rem);
    }
}

h2 {
    @include sub-font(1rem, 400, 1.25rem);

    @include md {
        @include sub-font(2rem, 400, 2rem);
    }
}

h3 {
    @include sub-font(.75rem, 400, 1rem);

    @include md {
        @include sub-font(1.5rem, 400, 2rem);
    }
}

h4,
p,
a {
    @include sub-font(1rem, 400, 1.2);

    @include md {
        @include sub-font(1.125rem, 400, 2rem);
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 3.125rem $portfolio-color--white inset;
    -webkit-text-fill-color: $portfolio-color--black;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 3.125rem $portfolio-color--white inset;
}

.hidden {
    display: none;
}

// All Animations

// This is to open ::after ::before from center
.animation--underline::after {
    width: 5rem;
    height: .1875rem;
}

.fade-in {
    opacity: 1;
    filter: blur(0);
    translate: 0rem 0rem;
}

// Slides in from right to left
.slide-in {
    display: flex;
    transform: translateX(100%);
    animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

// Floating animation
@keyframes floatUp {
    0% {
        translate: 0rem .9375rem;
    }

    50% {
        translate: 0rem 0rem;
    }

    100% {
        translate: 0rem .9375rem;
    }
}

@keyframes floatDown {
    0% {
        translate: 0rem -0.625rem;
    }

    50% {
        translate: 0rem 0rem;
    }

    100% {
        translate: 0rem -0.625rem;
    }
}


@keyframes wave-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-84.5%);
    }
}

@keyframes wave-right {
    0% {
        transform: translateX(-84.5%);
    }

    100% {
        transform: translateX(0);
    }
}

// Flip out from center animation

@mixin rotateLeft($rotateValue, $animationName) {
    @keyframes #{$animationName} {
        0% {
            translate: 10rem 0rem;
            rotate: ($rotateValue);
            scale: 0;
        }

        10% {
            rotate: 180deg;
            scale: 0;
        }

        100% {
            rotate: ($rotateValue);
            scale: 100%;
        }
    }
}

@mixin rotateRight($rotateValue, $animationName) {
    @keyframes #{$animationName} {
        0% {
            translate: -6.25rem 0rem;
            rotate: ($rotateValue);
            scale: 0;
        }

        10% {
            rotate: 80deg;
            scale: 0;
        }

        100% {
            rotate: ($rotateValue);
            scale: 100%;
        }
    }
}

//Pulse animation
@keyframes Pulse {
    0% {
        background-color: rgb(197, 197, 197);
    }

    50% {

        background-color: $skeleton-color--gray;
    }

    100% {
        background-color: rgb(197, 197, 197);
    }
}

//Shake animation
@keyframes shake {
    0% {
        rotate: 0deg;
    }

    25% {
        rotate: 2deg;
    }

    75% {
        rotate: -2deg;
    }

    100% {
        rotate: 0deg;
    }
}

//Sticker animation
@keyframes sticker {
    0% {
        rotate: -10deg;
    }

    50% {
        rotate: -5deg;
    }

    100% {
        rotate: -10deg;
    }
}

//Snappy Frames animation
@keyframes snapFrames {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

//pulse animation
.pulse-anim {
    animation: Pulse ease-in-out 1.2s infinite;
    transition: ease-in-out .5s;
}

// All status
#unknown {

    & .progress-bar__cont::after {
        overflow: hidden;
    }

    & img {
        filter: blur(.5rem);
    }
}

// All Media Queries

@media (prefers-reduced-motion) {

    .hero__mobile--img,
    .aboutme__mobile--img {
        animation: none !important;
    }

    .section-header--text {
        transition: none;

        &::before {
            width: .1875rem;
            height: 2rem;
        }
    }

    .aboutme__header--text::after {
        width: 5rem;
        height: .1875rem;
    }

    .aboutme__list--item {
        translate: 0;
        opacity: 1;
        filter: blur(0);
    }
}