@use '../../styles/partials/globals.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/breakpoints.scss' as *;

// Change only the --card-height to desired card size:
$card-height: 15rem;
$card-width: calc(#{$card-height} / 0.60);


.projCard {
    @include flex(column, center, center);
    width: 21.875rem;
    height: calc(#{$card-height} / 1.2);
    background-color: $portfolio-color--blue;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: 0rem .5rem 1rem 0rem rgba(0, 0, 0, 0.1);
    margin: .75rem;

    @include md {
        transition: ease .5s;
        width: $card-width;
        height: $card-height;
    }

    &:hover {
        @include md {
            background-color: $portfolio-color--blue;
            scale: 1.03;
            box-shadow: 0rem .5rem 1rem 0rem rgba(0, 0, 0, 0.06);

            & .projCard__body {
                bottom: 0rem;
            }

            & .projCard__title {
                filter: unset;

                &::after {
                    width: 80%;
                }
            }

            & .projCard__desc {
                opacity: 100%;
                filter: unset;
            }

            & .projCard--img {
                scale: 0.95;
                opacity: 60%;
                filter: blur(.375rem);
            }
        }
    }

    &__cont {
        @include flex(column, center, center);
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    &--img {
        background-color: $portfolio-color--pink;
        width: calc(#{$card-height} / 0.60);
        height: 100%;
        border-radius: .5rem;
        object-fit: cover;
        transition: ease .8s;
    }

    &__body {
        background-color: rgba($portfolio-color--white, 0.8);
        position: absolute;
        text-align: center;
        height: calc(#{$card-height} / -1);
        width: 101%;
        padding: .5rem 1rem;
        bottom: -50%;
        transition: ease .5s 0.1s;

        @include md {
            height: calc(#{$card-height} / 2.2);
        }
    }

    &__title {
        @include flex(column, center, center);
        @include sub-font(1rem, 700, 1.2);
        transition: ease .6s;
        filter: blur(.25rem);

        &::after {
            content: '';
            width: 0;
            border-radius: .5rem;
            margin: .25rem 0rem;
            height: .1875rem;
            background-color: $portfolio-color--pink;
            transition: ease-out 0.8s;
        }
    }

    &__desc {
        padding: .25rem 0rem;
        width: 100%;
        opacity: 0%;
        transition: ease .6s 0.1s;
        filter: blur(.25rem);

        @include md {
            @include sub-font(.75rem, 700, 1.5);
        }
    }
}